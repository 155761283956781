.damageCheck {
  margin: 6px;
  padding: 0px;
  font-size: inherit;
  background-color: #ffffff;
  color: #000000;
  width: 32px;
  height: 32px;
  border: 1px solid #000000;
  font-weight: bold;
}
.damageCheck--disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.damageCheck--selected {
  background-color: #000000;
  color: #ffffff;
}
.roundButton {
  border-radius: 45px;
  padding: 8px 0;
  font-size: 95%;
  background-color: #c9cfd6;
  color: #000000;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #c9cfd6;
}
.roundButton--checked {
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
}
.damagesModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
}
.damagesModal--hidden {
  display: none;
}
.damagesModal__title, .damagesModal__subtitle {
  margin: 0;
  margin-bottom: 3px;
}
.damagesModal__title {
  font-size: 105%;
}
.damagesModal__subtitle {
  font-size: 90%;
}
.damagesModal__options {
  margin: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.damagesModal__options .roundButton {
  margin-bottom: 16px;
}
.damagesModal__saveBtn {
  font-size: 100%;
  padding: 10px 0;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #000000;
  font-weight: bold;
}
.damagesModal__content {
  position: absolute;
  top: calc(50% - 187px);
  left: 0;
  right: 0;
  max-width: 80%;
  margin: 0 auto;
  box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
  text-align: center;
  padding: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
.damagesModal .other-input {
  min-height: 29px;
  font-family: "Trade Gothic", Helvetica, Arial, sans-serif;
  border-radius: 2px;
  border: 2px solid #c9cfd6;
  padding: 6px;
  resize: vertical;
}
.damagesModal .other-input:focus {
  border-color: #000000;
  outline: none;
}
.vehicleDamages {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.vehicleDamages__carImage {
  width: 90%;
}
.vehicleDamages__checks {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  padding: 16px;
}
.vehicleDamages #damages-notes {
  border-radius: 2px;
  border: 2px solid #c9cfd6;
  width: 95%;
  margin: 5px;
  resize: vertical;
}
.vehicleDamages #damages-notes:focus {
  border-color: #000000;
  outline: none;
}
.confirm-button {
  display: block;
  margin: 16px;
  margin-top: 0;
  padding: 16px;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #000000;
  border-radius: 11px;
  font-size: 120%;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
}
button:disabled.confirm-button {
  pointer-events: none;
  background-color: #979797;
  border-color: #979797;
}
.sketch-map {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}
.sketch-map .container-base1 {
  background-size: cover;
}
.sketch-map .container-base2 {
  background-repeat: no-repeat;
  background-size: contain;
}
.sketch-map #sketch-container {
  flex: 1;
  position: relative;
}
.sketch-map #sketch-container .drawing {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
.sketch-map #sketch-container .icon {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  width: 20vw;
  height: 20vw;
  transition: box-shadow 0.5s, opacity 0.5s;
  will-change: transform;
  cursor: -webkit-grab;
  cursor: grab;
  z-index: 1;
}
.sketch-map #sketch-container .icon img {
  width: 20vw;
  height: 20vw;
}
.sketch-map #sketch-container .icon--medium img {
  width: 14vw;
  height: 14vw;
}
.sketch-map #sketch-container .icon--small img {
  width: 10vw;
  height: 10vw;
}
.sketch-map #sketch-container .icon.dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.sketch-map #sketch-container .icon.disabled {
  opacity: 0.5;
  cursor: default;
}
.sketch-map #sketch-container button.eraserIcon,
.sketch-map #sketch-container button.info,
.sketch-map #sketch-container button.drawIcon,
.sketch-map #sketch-container button.rotateIcon {
  position: absolute;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  top: 15px;
  right: 10px;
  float: right;
  color: #000000;
  text-align: center;
  padding: 0;
  z-index: 50;
  height: 42px;
  width: 42px;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
  background-color: white;
  border-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.sketch-map #sketch-container button.eraserIcon.active,
.sketch-map #sketch-container button.info.active,
.sketch-map #sketch-container button.drawIcon.active,
.sketch-map #sketch-container button.rotateIcon.active {
  background-color: #e6702a;
}
.sketch-map #sketch-container button.eraserIcon:active, .sketch-map #sketch-container button.eraserIcon:focus,
.sketch-map #sketch-container button.info:active,
.sketch-map #sketch-container button.info:focus,
.sketch-map #sketch-container button.drawIcon:active,
.sketch-map #sketch-container button.drawIcon:focus,
.sketch-map #sketch-container button.rotateIcon:active,
.sketch-map #sketch-container button.rotateIcon:focus {
  outline: none;
}
.sketch-map #sketch-container button.eraserIcon img,
.sketch-map #sketch-container button.info img,
.sketch-map #sketch-container button.drawIcon img,
.sketch-map #sketch-container button.rotateIcon img {
  height: 25px;
  margin-top: 3px;
  margin-left: 2px;
  pointer-events: none;
}
.sketch-map #sketch-container button.info img {
  height: 45px;
  width: 45px;
  margin-left: -1px;
}
.sketch-map #sketch-container button.rotateIcon {
  margin-top: 3rem;
}
.sketch-map #sketch-container button.drawIcon {
  margin-top: 6rem;
}
.sketch-map #sketch-container button.eraserIcon {
  margin-top: 9rem;
}
.sketch-map #sketch-container button.delete {
  position: absolute;
  height: 50px;
  width: 50px;
  bottom: 25px;
  right: 5px;
  padding: 0;
  background-color: #e6702a;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  z-index: 50;
}
.sketch-map #sketch-container button.delete:active, .sketch-map #sketch-container button.delete:focus {
  outline: none;
}
.sketch-map #sketch-container button.delete img {
  height: 50px;
  width: 50px;
}
.iconRotateSketch {
  height: 1.2rem;
  width: 1.5rem;
  position: absolute;
  bottom: 61px;
  right: -12px;
}
.areaRotateSketch {
  border: 3px dotted;
  border-color: #504f4f;
}
.sketch-map #sketch-container .icon .selectedIconSketch {
  width: 18vw;
  height: 18vw;
}
.message-window img.action-gif {
  margin-top: 16px;
  height: 90px;
}
.message-window .rotate-gesture-page img {
  width: 17rem;
  margin-left: -1rem;
  height: 5.7rem;
}
@media (max-width: 370px) {
  .message-window .rotate-gesture-page img {
    width: 13rem;
    margin-left: -0.5rem;
    height: 4rem;
  }
}
@media (max-width: 280px) {
  .message-window .rotate-gesture-page img {
    width: 11rem;
    margin-left: -0.8rem;
    height: 4rem;
  }
}
.sketch-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 8px;
  align-content: center;
}
.sketch-toolbar .symbol-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sketch-toolbar .symbol-container .symbol {
  align-self: center;
  height: 32px;
}
.sketch-toolbar .symbol-container .symbol-name {
  margin-top: 6px;
  text-align: center;
  font-weight: bold;
  font-size: 70%;
}
.message-window-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 50 !important;
}
.message-window-container .message-window {
  min-height: 300px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.message-window-container .message-window .container {
  box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
  text-align: center;
  margin: 0 calc(16px * 3);
  padding: 16px;
  background-color: #ffffff;
  display: flex;
  height: auto;
  flex-direction: column;
}
.message-window-container .message-window .container img.close {
  align-self: flex-end;
  width: 20px;
  height: 20px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.message-window-container .message-window .container h4,
.message-window-container .message-window .container h5 {
  margin: 0;
  margin-bottom: 3px;
}
.message-window-container .message-window .container h4 {
  font-size: 105%;
}
.message-window-container .message-window .container h5 {
  font-size: 90%;
}
.message-window-container .message-window .container p {
  margin: 16px 0;
  font-size: 85%;
}
.message-window-container .message-window .container .default {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.message-window-container .message-window .container .default .body {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.message-window-container .message-window .container .buttons-container {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.message-window-container .message-window .container .buttons-container button.sm {
  font-size: 100%;
  padding: 6px 0;
  margin-bottom: 10px;
  width: 100%;
}
.message-window-container .message-window .container .buttons-container button.remove {
  background-color: #c30909;
  border-color: #c30909;
}
.hidden {
  display: none;
}
.map {
  flex: 1;
  position: relative;
}
.map .location-access {
  height: 100%;
  display: flex;
  justify-content: center;
}
.map .location-access button {
  align-self: center;
  padding: 10px;
}
.map__gpsOff {
  z-index: 10;
  position: absolute;
  top: 6px;
  right: 10px;
  background-color: #e6702a;
  color: #ffffff;
  font-size: 12px;
  padding: 8px 10px;
  border-radius: 16px;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  display: flex;
}
.map__gpsOffIcon {
  filter: brightness(0) invert(1);
  margin-left: 8px;
}
.map #map {
  height: 100%;
  width: 100%;
}
.map .center-pin {
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc(50% - 50px);
  left: calc(50% - 20px);
}
.location-search {
  padding: 16px;
}
.location-search .input-container {
  display: flex;
  flex-direction: row;
  background-color: #e7ebec;
  align-items: center;
  /*
  Google's AutoComplete Results width will be the same as its target input.
  Since we want the results to be full-width, we position the icons absolutely
  in the container and set big paddings on left and right of input so as 
  to give the icons and make it look the input starts to the right of icon.
  */
  position: relative;
}
.location-search .input-container img {
  display: flex;
  height: 20px;
  width: 20px;
  position: absolute;
}
.location-search .input-container .pin {
  left: 10px;
}
.location-search .input-container .lupa {
  right: 10px;
}
.location-search .input-container input,
.location-search .input-container input:focus {
  display: flex;
  flex: 1;
  background-color: #e7ebec;
  border: none;
  font-weight: bold;
  -webkit-appearance: none;
  box-shadow: 0;
  outline: none;
  padding: 10px 40px;
}
.location-search .input-container ::-moz-placeholder {
  font-weight: bold;
}
.location-search .input-container :-ms-input-placeholder {
  font-weight: bold;
}
.location-search .input-container ::placeholder {
  font-weight: bold;
}
.pac-container {
  border-top: none;
  box-shadow: none;
}
.pac-container .pac-item {
  padding: 4px;
  border-top: none;
  border-bottom: 1px solid #e7ebec;
}
.confirm-window {
  display: flex;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 40vh;
  flex-direction: column;
  z-index: 3;
}
.confirm-window .container {
  box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
  text-align: center;
  margin: 0 calc(16px * 3);
  padding: 16px;
  background-color: #ffffff;
  display: flex;
  height: auto;
  flex-direction: column;
}
.confirm-window .container img {
  align-self: flex-end;
  width: 26px;
  height: 26px;
}
.confirm-window .container h4,
.confirm-window .container h5 {
  margin: 0;
  margin-bottom: 3px;
}
.confirm-window .container h4 {
  font-size: 105%;
}
.confirm-window .container h5 {
  font-size: 90%;
}
.confirm-window .container .buttons-container {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.confirm-window .container .buttons-container button.sm {
  font-size: 100%;
  padding: 6px 0;
  margin-bottom: 10px;
  width: 100%;
}
.confirm-window .container .buttons-container button.remove {
  background-color: #c30909;
  border-color: #c30909;
}
.confirm-window .container .radio-container {
  margin: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
}
.hidden {
  display: none;
}
.thumbnail-wrapper {
  display: flex;
  position: relative;
}
.thumbnail-wrapper img.remove {
  position: absolute;
  height: 10px;
  width: 10px;
  right: 0;
  background-color: #e7ebec;
  padding: 3px;
  border-radius: 30px;
}
.thumbnail-wrapper.warning .thumbnail {
  border: 2px solid #f87000;
}
.thumbnail-wrapper.isUploading {
  opacity: 0.5;
}
.thumbnail-wrapper .loading {
  position: absolute;
  width: 65px;
  height: 65px;
}
.thumbnail-wrapper .thumbnail {
  border: 2px solid #ffffff;
  background-color: #ffffff;
  height: 70px;
  width: 70px;
  margin: 0 3px;
  display: flex;
  justify-content: center;
  margin-top: 6px;
  box-sizing: border-box;
}
.thumbnail-wrapper .thumbnail img.placeholder {
  align-self: center;
  margin: auto;
  height: 52px;
  width: 52px;
}
.thumbnail-wrapper .thumbnail img.no-margins {
  -o-object-fit: cover;
     object-fit: cover;
  height: 65px;
  width: 65px;
}
.thumbnail-wrapper .thumbnail-selected {
  border: 3px solid #e6702a;
}
.thumbnail-wrapper .warning-overlay {
  background: rgba(100, 100, 100, 0.9);
  width: 65px;
  height: 65px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.camera-roll::-webkit-scrollbar {
  display: none;
}
.camera-roll {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  height: 130px;
  align-items: flex-end;
}
.camera-roll #image-upload {
  display: none;
}
.camera-shutter .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}
.camera-shutter .container .shutter-button {
  display: flex;
  position: absolute;
  align-self: center;
  border: 3px solid #ffffff;
  border-radius: 45px;
  height: 53px;
  width: 53px;
}
.camera-shutter .container .shutter-button img {
  width: 2.8rem;
  height: 2.7rem;
  margin: 8% 8% 0%;
}
.camera-shutter .container .continue-button {
  position: relative;
  left: 76px;
  align-self: center;
  height: 55px;
  width: 55px;
  margin-left: 30%;
}
.camera-shutter .container .continue-button.disabled {
  pointer-events: none;
}
.camera-shutter .container .continue-button img {
  height: 100%;
}
.camera-shutter .container .continue-button.disabled img {
  opacity: 0.4;
}
.camera-viewfinder {
  display: flex;
  position: absolute;
  overflow: hidden;
  background: #adadad;
  text-align: right;
  height: 100%;
  width: 100%;
  z-index: -1;
  justify-content: center;
}
.camera-viewfinder video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  align-self: center;
}
.camera-viewfinder .preview {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.camera-viewfinder video {
  -o-object-fit: cover;
     object-fit: cover;
}
@-webkit-keyframes _HUqgD {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@keyframes _HUqgD {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.camera-viewfinder .flash {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-animation: flash 1s;
  animation: flash 1s;
}
.camera-viewfinder .remove {
  height: 20px;
  width: 20px;
  margin-top: 20px;
  right: 20px;
  position: absolute;
  z-index: 2;
}
.camera-viewfinder .warnings {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  color: white;
  background: rgba(105, 105, 105, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 120px 20px;
  box-sizing: border-box;
  z-index: 1;
}
.message-window-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
}
.message-window-container .message-window {
  min-height: 300px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.message-window-container .message-window .container {
  box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
  text-align: center;
  margin: 0 calc(16px * 3);
  padding: 16px;
  background-color: #ffffff;
  display: flex;
  height: auto;
  flex-direction: column;
}
.message-window-container .message-window .container img.close {
  align-self: flex-end;
  width: 20px;
  height: 20px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.message-window-container .message-window .container h4,
.message-window-container .message-window .container h5 {
  margin: 0;
  margin-bottom: 3px;
}
.message-window-container .message-window .container h4 {
  font-size: 105%;
}
.message-window-container .message-window .container h5 {
  font-size: 90%;
}
.message-window-container .message-window .container p {
  margin: 16px 0;
  font-size: 85%;
}
.message-window-container .message-window .container .default {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.message-window-container .message-window .container .default .body {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.message-window-container .message-window .container .buttons-container {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.message-window-container .message-window .container .buttons-container button.sm {
  font-size: 100%;
  padding: 6px 0;
  margin-bottom: 10px;
  width: 100%;
}
.message-window-container .message-window .container .buttons-container button.remove {
  background-color: #c30909;
  border-color: #c30909;
}
.hidden {
  display: none;
}
.camera-viewfinder {
  flex: 1;
}
.camera-options {
  overflow: auto;
  margin: auto auto 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.camera-options .photos .photo-description {
  margin-bottom: calc(16px * 1.5);
  color: #ffffff;
}
.camera-options .camera-shutter {
  flex: 1;
  height: 100px;
}
.camera-options .camera-shutter .container {
  height: 100%;
}
.camera-options .button-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.camera-options .button-container .confirm-button {
  align-self: flex-end;
  width: 100%;
}
.completed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  color: #ffffff;
}
.completed h1 {
  font-size: 22px;
}
.completed img {
  align-self: center;
  height: 120px;
  width: 120px;
}
.completed p {
  margin: 0;
  font-size: 1rem;
  color: #ffffff;
}
.completed .checkmark {
  height: 98px;
  width: 90px;
}
.completed .photoIcon {
  height: 16rem;
  width: 17rem;
  margin-top: -8rem;
}
.completed .message {
  margin: 3rem 1rem 0rem;
}
.completed .continue {
  background-color: #ffffff;
  border: none;
  display: block;
  margin: 16px;
  padding: 16px;
  color: #e6702a;
  border-radius: 11px;
  font-size: 120%;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  width: calc(100% - 32px);
  box-sizing: border-box;
}
.fab {
  float: right;
  position: absolute;
  top: 0.2rem;
  right: 9px;
}

._35UJB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._35UJB ._3FsLV {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 200px;
  align-items: center;
  background-color: #e6702a;
  width: 70px;
  height: 70px;
  margin: 16px;
}
._35UJB ._3FsLV img {
  padding: 4px;
  margin: 0px;
  height: 60px;
  width: 60px;
}
._35UJB ._1miLN {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 200px;
  align-items: center;
  background-color: #e6702a;
  width: 70px;
  height: 70px;
  margin: 16px;
}
._35UJB ._1miLN img {
  padding: 4px;
  margin: 0px;
  height: 60px;
  width: 60px;
}
._35UJB p {
  margin: 8px 0;
}
.camera-tooltip-container {
  display: flex;
  justify-content: flex-end;
}
.camera-tooltip-container.opened .camera-tooltip {
  width: 100%;
  min-height: 300px;
}
.camera-tooltip {
  background-color: white;
  color: #545454;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
}
.camera-tooltip .header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  font-size: 20px;
}
.camera-tooltip .header span {
  margin-right: 8px;
  font-weight: bold;
}
.camera-tooltip .header .info-icon {
  color: #545454;
  height: 20px;
  width: 20px;
}
.camera-tooltip .content {
  margin-top: 40px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.camera-tooltip .content .image-container {
  height: 200px;
  /* Can be anything */
  position: relative;
}
.camera-tooltip .content .image-container img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.camera-tooltip .content p {
  text-align: center;
}
.camera-tooltip .content ul {
  margin-top: 0;
  text-align: left;
}
.camera-tooltip .content button {
  padding: 10px 10px;
  border: none;
  border-radius: 3px;
  background-color: #545454;
  color: white;
  margin: 0 16px 16px;
}
audio {
  width: 50%;
}
.label-story {
  font-weight: bold;
  color: #C96528;
}
.icon-info {
  padding: 10px;
  color: blue;
}
.text-info {
  padding-top: 16px;
  font-size: 12px;
  color: blue;
}
.audio-react-recorder {
  display: none;
}
.sound-wave {
  display: none;
}
.flex {
  display: flex;
}
.contents {
  display: contents;
}
.img-transcript {
  height: 30px;
  width: 35px;
  padding-top: 12px;
  cursor: pointer;
}
.time {
  text-align: center;
  padding-top: 15px;
  font-weight: bold;
}
.confirm-modal {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: auto;
  padding: 16px;
}
.confirm-modal .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 36px;
  border-radius: 16px;
}
.confirm-modal .container:focus {
  outline: none;
}
.confirm-modal .container .buttons-container {
  display: flex;
  flex-direction: row;
}
.confirm-modal .container .buttons-container Button {
  margin: 0 8px;
}
.modal-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-backdrop .modal {
  background: white;
  margin: 15px;
  padding: 15px;
  border-radius: 4px;
}
.modal-backdrop .modal h2 {
  text-align: center;
  margin-top: 0;
}
.modal-backdrop .modal div {
  margin-bottom: 15px;
}
.modal-backdrop .modal button {
  margin: 0;
  width: 100%;
}
button.continue-btn.MuiButton-root {
  margin: 16px;
  width: calc(100% - 32px);
  font-size: 16px;
  border: #cc6a2f;
}
button.continue-btn.MuiButton-root:not(.disabled) {
  background-color: #cc6a2f;
  color: #ffffff;
}
button.continue-btn.MuiButton-root.connect {
  border: #cc6a2f;
}
button.continue-btn.MuiButton-root.connect:not(.disabled) {
  background-color: #cc6a2f;
}
button.continue-btn.MuiButton-root.is {
  border: #0b9548;
}
button.continue-btn.MuiButton-root.is:not(.disabled) {
  background-color: #0b9548;
}
button.MuiButton-root.delete-btn {
  margin: 16px;
  border: #000000;
  width: calc(100% - 32px);
  font-size: 16px;
}
button.MuiButton-root.delete-btn:not(.disabled) {
  background-color: #000000;
  color: #ffffff;
}
button.MuiButton-root.delete-btn svg {
  margin-right: 0.8rem;
  margin-top: -0.1rem;
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
.loading h2 {
  text-align: center;
  padding: 0 1rem;
}
.warning {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
}
.warning__container {
  padding: 0 1rem;
  width: 90%;
}
.warning__container button {
  width: 100%;
  border: 0px;
  font-size: 14px;
  padding: 9px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 5rem;
}
.warning--alert {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}
.photo-section-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.photo-section-screen .container {
  height: 100%;
  max-height: calc(100% - 42px - 32px);
}
.photo-section-screen h3.lead {
  display: flex;
  align-items: center;
  margin-top: 15px;
  line-height: 22px;
  margin-bottom: 35px;
  margin-left: 15px;
  margin-right: 15px;
}
.photo-section-screen h3.lead svg {
  height: 20px;
  margin-right: 15px;
}
.photo-section-screen h3.lead span {
  margin-right: 15px;
}
.photo-section-screen .photos-scrollable-area {
  width: 100%;
  max-height: calc( 				100% - 15px - 22px - 35px 			);
  overflow: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.photo-section-screen .photos-scrollable-area input[type=file] {
  display: none;
}
.photo-section-screen .photos-container {
  flex: 1;
  max-width: 340px;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.photo-section-screen .photos-container .image-upload-thumbnail,
.photo-section-screen .photos-container .image-preview-thumbnail {
  height: 230px;
  width: calc(50% - 5px);
  max-width: 170px;
  position: relative;
}
.photo-section-screen .photos-container .image-upload-thumbnail .custom-file-upload,
.photo-section-screen .photos-container .image-preview-thumbnail .custom-file-upload {
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
}
.photo-section-screen .photos-container .image-upload-thumbnail .custom-file-upload .camera-plus-icon,
.photo-section-screen .photos-container .image-preview-thumbnail .custom-file-upload .camera-plus-icon {
  width: 50px;
  height: 50px;
}
.photo-section-screen .photos-container .image-upload-thumbnail .ayuda,
.photo-section-screen .photos-container .image-preview-thumbnail .ayuda {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px auto 0;
  width: 90%;
  padding: 8px;
  color: white;
  background: #222;
  border-radius: 50px;
}
.photo-section-screen .photos-container .image-upload-thumbnail .ayuda svg,
.photo-section-screen .photos-container .image-preview-thumbnail .ayuda svg {
  width: 22px;
  margin-right: 6px;
  fill: white;
}
.photo-section-screen .photos-container .image-upload-thumbnail .ayuda span,
.photo-section-screen .photos-container .image-preview-thumbnail .ayuda span {
  line-height: 20px;
}
.photo-section-screen .photos-container .image-preview-thumbnail {
  position: relative;
}
.photo-section-screen .photos-container .image-preview-thumbnail img {
  height: 170px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  box-sizing: border-box;
}
.photo-section-screen .photos-container .image-preview-thumbnail .preview-label {
  position: relative;
  top: -43px;
  background: #000000c9;
  color: white;
  height: 19px;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #ddd;
  border-top: 0px;
}
.photo-section-screen .button-panel {
  box-shadow: 0 12px 16px black;
  z-index: 1;
  padding-bottom: 0.1rem;
}
.photo-section-screen .full-screen-preview {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #000000dd;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 2;
}
.photo-section-screen .full-screen-preview img {
  width: 100%;
  max-height: 80%;
}
.photo-section-screen .full-screen-preview .action-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
}
.photo-section-screen .full-screen-preview .action-buttons button {
  width: 100%;
  border: 0px;
  font-size: 14px;
  padding: 9px;
  border-radius: 5px;
  font-weight: bold;
}
.photo-section-screen .full-screen-preview .action-buttons .remove {
  background-color: #ff7777;
}
.photo-help-modal img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.record-container {
  width: 100%;
  height: 100%;
  background-color: blue;
  z-index: 2;
  position: absolute;
}
.error-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  text-align: center;
}
.render-actions .actions-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.render-actions .root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Menlo, monospace;
  font-size: 100px;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
}
.render-actions .text {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-family: Menlo, monospace;
  font-size: 1.2rem;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 0.4rem;
}
.render-actions .rec-icon {
  width: 16px;
  height: 16px;
  background: #e55226;
  border-radius: 50%;
  float: left;
  margin: 2px 8px;
  margin-left: 0;
}
.render-actions .rec-wrapper {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.render-actions .rec-wrapper .rec-text {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 2rem;
  border-radius: 8px;
  width: 70%;
  text-align: center;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: lighter;
  margin-bottom: 1rem;
}
.render-actions .button-border {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.render-actions .button-border .rec-button {
  background: #db4437;
  color: #db4437;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background: #db4437;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 5;
}
.render-actions .button-border .stop-button {
  background: #db4437;
  color: #db4437;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: #db4437;
  outline: none;
  border: none;
  cursor: pointer;
}
.render-actions .stop-wrapper {
  align-self: flex-end;
}
.action-button {
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  background-color: #EFEFEF;
  color: black;
}
.action-button svg {
  margin-right: 0.5rem;
}